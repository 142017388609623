<template>
  <div class="portfolio-page">
    <HeaderFive>
      <img slot="logo" src="../assets/img/logo/portfolio.png" />
    </HeaderFive>
    <!-- End Header -->

    <div id="item-details"> </div>


    <Footer />
    <!-- End Footer -->
  </div>
</template>

<script>
  import HeaderFive from "../components/header/HeaderFive";
  // import PortfolioFive from "../components/portfolio/PortfolioFive";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderFive,
      // PortfolioFive,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .rn-portfolio-area.section-ptb-100
    .masonry-tabs
    .v-slide-group__wrapper
    .v-slide-group__content {
    justify-content: center !important;
  }
  .portfolio-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
